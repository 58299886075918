import React from 'react'
import { MDXProvider } from '@mdx-js/react'

// import each component (these all live in the same 'components' folder)
import SlideShow from './slideshow.js'
import SlideShow2 from './slideshow2.js'
import Bio from './Bio.js'
import Video from './video.js'


// set up a dictionary of imported components
const GlobalScopeComponents = {
  SlideShow: SlideShow,
  SlideShow2: SlideShow2,
  Video: Video,
  Bio: Bio,
}

// export a MDXWrapper component, which will pass these components into the child mdx posts
export const MDXWrapper = ({ children }) => {
  return (
    <MDXProvider components={{ ...GlobalScopeComponents }}>
      {children}
    </MDXProvider>
  )
}